import "./styles.scss";

import React from "react";

import Decor from "components/Icons/Decor";
import Button from "components/Button";

const mainClass = "thank-you";

const ThankYouContent = ({ location }) => {
  const utm_source = new URLSearchParams(location).get("utm_source");
  const utm_medium = new URLSearchParams(location).get("utm_medium");
  const utm_campaign = new URLSearchParams(location).get("utm_campaign");

  const utms_formatted = `${!!utm_source ? `?utm_source=${utm_source}` : ""}${
    !!utm_medium ? `&utm_medium=${utm_medium}` : ""
  }${!!utm_campaign ? `&utm_campaign=${utm_campaign}` : ""}`;

  return (
    <section className={mainClass}>
      <div className={`${mainClass}__wrapper`}>
        <Decor />
        <h1 className={`${mainClass}__heading`}>Dziękujemy za zapytanie</h1>
        <p className={`${mainClass}__text`}>Wkrótce do Ciebie zadzwonimy.</p>
        <Button href={`/${utms_formatted}`}>WRÓĆ DO STRONY GŁÓWNEJ</Button>
      </div>
    </section>
  );
};

export default ThankYouContent;
