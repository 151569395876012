import React from "react";

import Layout from "components/Layout";
import ThankYouContent from "../page_components/thank-you/ThankYouContent";

const ThankYou = ({ location }) => {
  const { hash, pathname, search } = location;

  return (
    <Layout
      seo={{
        title: "Dziękujemy za zapytanie",
      }}
      location={{ pathname, hash }}
      search={search}
    >
      <ThankYouContent location={search} />
    </Layout>
  );
};

export default ThankYou;
